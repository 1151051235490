
import store from "@/store/index";
import { EmployeeController, EmployeegroupController, WorkController, DepartmentController } from '@/controller';
import { Card, Button, IconButton, Checkbox, CheckboxMultipleSelect, FileUpload, SelectSingle, DateInput } from '@/ui/index';
import { defineComponent } from 'vue';
import { Work, Employee, Employeegroup, Address, Contact, Department } from '@/model';
import { DateTimeUtils } from '@/utils';

export default defineComponent({
    name: "EmployeeForm",
    components: { Card, Button, IconButton, Checkbox, CheckboxMultipleSelect, FileUpload, SelectSingle, DateInput },
    data() {
        return{
            store,
            editMode: false,
            form:{
                uid: 0,
                title: "",
                workplace: 0,
                gender: 0,
                firstName: "",
                lastName: "",
                siNumber: 0,
                dateOfBirth: '',
                address: "",
                zip: '',
                mailPrivate: "",
                city:"",
                telephone: "",
                number: '',
                username: '',
                group: 0,
                works: [] as Array<Work>,
                departments: [] as Array<number>
            },
            profileImage: null,
            fetchedWorks: [] as Array<Work>,
            allowLogin: false,
            employee: {} as Employee | null,
            employeegroups: [] as Array<Employeegroup>,
            departments: [] as Array<Department>
        }
    },
    computed:{
        getProfileImageSrc() {
            return "";
        },
        departmentOptions() {
            const result = [] as Array<any>
            this.departments.forEach(d => {
                result.push({
                    'uid': d.uid,
                    'title': d.title,
                    'assigned': (this.form.departments.includes(d.uid))
                })
            })
            return result
        }
    },
    created() {
        this.fetchWorks()
        this.fetchEmployeegroups()
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true;
            this.getEmployee(this.$route.params.id);
        } else {
            this.editMode = false;
            this.fetchWorks();
        }
        this.fetchDepartments()
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        handleSetProfileImage(file:any) {
            this.profileImage = file;
            console.log("handleSetProfileImage", this.profileImage)
        },
        handleSubmitPrevent(){
            if (this.editMode) {
                this.handleSave();
            } else {
                this.handleCreate();
            }
        },
        handleAllowLoginChange(value: any) {
            console.log('handleAllowLoginChange', value)
            this.allowLogin = value
            if (!this.allowLogin) {
                this.form.username = ''
            } else {
                this.form.username = this.employee?.feUser?.getUsername() ? this.employee?.feUser?.getUsername() : ''
            }
        },
        handleEmployeegroupChange(value: number) {
            this.form.group = value
        },
        handleDepartmentChange(department: any, value: number) {
            if (value) {
                this.form.departments.push(department.uid)
            } else {
                const idx = this.form.departments.indexOf(department.uid)
                if (idx>-1) this.form.departments.splice(idx,1)
            }
        },
        setFormData(employee: Employee) {
            this.form.uid = employee.getUid()
            this.form.firstName = employee.getFirstName()
            this.form.lastName = employee.getLastName()
            this.form.title = employee.getTitle()
            this.form.gender = employee.getGender()
            this.form.workplace = employee.getWorkplace()
            this.form.siNumber = employee.getSiNumber()
            this.form.dateOfBirth = DateTimeUtils.formatDateForInput(DateTimeUtils.convertLinuxDateStringToDate(employee.getDateOfBirth()))
            const address = employee.getAddress() ? employee.getAddress() : new Address(0, '','','')
            this.form.address = (address ? address.getAddress() : '')
            this.form.zip = (address ? address.getZip() : '')
            this.form.city = (address ? address.getCity() : '')
            const contact = employee.getContact() ? employee.getContact() : new Contact(0, '','')
            this.form.mailPrivate = (contact ? contact.getEmail() : '')
            this.form.telephone = (contact ? contact.getPhone() : '')
            this.form.number = employee.getNumber()
            const feUser = employee.getFeUser() ? employee.getFeUser() : null
            this.form.username = (feUser ? feUser.getUsername() : '')
            this.allowLogin = this.form.username != ''
            const group = employee.getGroup() ? employee.getGroup() : null
            this.form.group = (group ? group.getUid() : 0)
            this.employee = employee
            this.form.departments = []
            this.employee.getDepartments().forEach(d => this.form.departments.push(d.uid))
        },
        async getEmployee(uid:any) {
            const res = await EmployeeController.getEmployeeById(uid);
            if (!res.error) {
                this.setFormData(res.employee)
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async fetchEmployeegroups() {
            const res = await EmployeegroupController.fetchEmployeegroups();
            if (!res.error) {
                this.employeegroups = res.items
                //
                console.log('fetchEmployeegroups res', res);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async fetchWorks() {
            const res = await WorkController.fetchWorks();
            if(!res.error){
                this.fetchedWorks = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async fetchDepartments() {
            const res = await DepartmentController.fetchDepartments();
            if(!res.error){
                this.departments = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },        
        async handleCreate() {
            const res = await EmployeeController.createEmployee(this.form, this.profileImage);
            if (!res.error) {
                this.$notify(this.$t("success.employeeCreated"), { position: "top" });
                this.$router.push("/administration/employee/detail/" + res.employee.uid);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleSave() {
            const res = await EmployeeController.updateEmployee(this.form);
            if (!res.error) {
                this.form = res.employee;
                this.$notify(this.$t("success.employeeUpdated"), { position: "top", type: "success" });
                this.$router.push("/administration/employee/detail/" + res.employee.uid);
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
