import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/img/kein_bild.jpg'


const _hoisted_1 = { class: "timi-administration-container timi-administration-container-work" }
const _hoisted_2 = { class: "form-layout" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-6" }
const _hoisted_5 = { class: "timi-form-item col-6" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "timi-form-item col-6" }
const _hoisted_8 = { class: "timi-form-item col-6" }
const _hoisted_9 = ["placeholder"]
const _hoisted_10 = { class: "timi-form-item col-6" }
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "timi-form-item" }
const _hoisted_13 = ["placeholder"]
const _hoisted_14 = { class: "timi-form-item col-4" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = { class: "timi-form-item col-8" }
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = { class: "timi-form-item col-6" }
const _hoisted_19 = ["placeholder"]
const _hoisted_20 = { class: "timi-form-item col-6" }
const _hoisted_21 = ["placeholder"]
const _hoisted_22 = { class: "col-6" }
const _hoisted_23 = { class: "timi-form-item col-3" }
const _hoisted_24 = ["placeholder"]
const _hoisted_25 = { class: "timi-form-item col-3" }
const _hoisted_26 = { class: "timi-form-item col-6" }
const _hoisted_27 = ["placeholder"]
const _hoisted_28 = { class: "timi-form-item" }
const _hoisted_29 = {
  key: 0,
  class: "timi-form-item col-6"
}
const _hoisted_30 = {
  key: 1,
  class: "timi-form-item col-6"
}
const _hoisted_31 = { class: "timi-form-item col-6" }
const _hoisted_32 = ["disabled", "placeholder"]
const _hoisted_33 = { class: "row" }
const _hoisted_34 = { class: "col-6" }
const _hoisted_35 = { class: "timi-edit-profile-image" }
const _hoisted_36 = { class: "profile-image-rd" }
const _hoisted_37 = ["src"]
const _hoisted_38 = {
  key: 1,
  src: _imports_0
}
const _hoisted_39 = { class: "col-6" }
const _hoisted_40 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_SelectSingle = _resolveComponent("SelectSingle")!
  const _component_Card = _resolveComponent("Card")!
  const _component_DateInput = _resolveComponent("DateInput")!
  const _component_CheckboxMultipleSelect = _resolveComponent("CheckboxMultipleSelect")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconButton, {
      icon: _ctx.store.getters.svgIcons.arrowbackward,
      class: "go-back-btn",
      label: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["icon", "label", "onOnClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[15] || (_cache[15] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Card, {
              title: _ctx.$t('employee.personalData'),
              class: "transparent border flex"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.title')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.title'),
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event))
                  }, null, 8, _hoisted_6), [
                    [_vModelText, _ctx.form.title]
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.gender')), 1),
                  _createVNode(_component_SelectSingle, {
                    placeholder: _ctx.$t('placeholder.gender'),
                    item: {},
                    options: [{uid: 0, title:'männlich'}, {uid: 1, title:'weiblich'}, {uid: 2, title:'divers'}],
                    value: _ctx.form.gender,
                    disabled: false,
                    onOnChange: _cache[1] || (_cache[1] = (value)=>_ctx.form.gender = value)
                  }, null, 8, ["placeholder", "value"])
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.firstname')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.firstname'),
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.firstName) = $event))
                  }, null, 8, _hoisted_9), [
                    [_vModelText, _ctx.form.firstName]
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.lastname')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.lastname'),
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.lastName) = $event))
                  }, null, 8, _hoisted_11), [
                    [_vModelText, _ctx.form.lastName]
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.street')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.street'),
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.address) = $event))
                  }, null, 8, _hoisted_13), [
                    [_vModelText, _ctx.form.address]
                  ])
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.zip')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.zip'),
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.zip) = $event))
                  }, null, 8, _hoisted_15), [
                    [_vModelText, _ctx.form.zip]
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.city')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.city'),
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.city) = $event))
                  }, null, 8, _hoisted_17), [
                    [_vModelText, _ctx.form.city]
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.telephone')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.telephone'),
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.telephone) = $event))
                  }, null, 8, _hoisted_19), [
                    [_vModelText, _ctx.form.telephone]
                  ])
                ]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.mailPrivate')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "email",
                    placeholder: _ctx.$t('placeholder.mail'),
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.mailPrivate) = $event))
                  }, null, 8, _hoisted_21), [
                    [_vModelText, _ctx.form.mailPrivate]
                  ])
                ])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_component_Card, {
              title: _ctx.$t('employee.companyData'),
              class: "transparent border flex"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.siNumber')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.siNumber'),
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.siNumber) = $event))
                  }, null, 8, _hoisted_24), [
                    [_vModelText, _ctx.form.siNumber]
                  ])
                ]),
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.dateOfBirth')), 1),
                  _createVNode(_component_DateInput, {
                    value: _ctx.form.dateOfBirth,
                    onOnChange: _cache[10] || (_cache[10] = (newValue) => _ctx.form.dateOfBirth = newValue)
                  }, null, 8, ["value"])
                ]),
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.employeeNumber')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    placeholder: _ctx.$t('placeholder.employeeNumber'),
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.form.number) = $event))
                  }, null, 8, _hoisted_27), [
                    [_vModelText, _ctx.form.number]
                  ])
                ]),
                _createElementVNode("div", _hoisted_28, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.employeegroup')), 1),
                  _createVNode(_component_SelectSingle, {
                    options: _ctx.employeegroups,
                    value: _ctx.form.group,
                    onOnChange: _ctx.handleEmployeegroupChange
                  }, null, 8, ["options", "value", "onOnChange"])
                ]),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('employee.workplace')), 1),
                      _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.workplace')), 1),
                      _createVNode(_component_SelectSingle, {
                        placeholder: _ctx.$t('placeholder.workplace'),
                        options: [],
                        value: _ctx.form.workplace,
                        disabled: false,
                        onOnChange: _cache[12] || (_cache[12] = (value)=>_ctx.form.workplace = value)
                      }, null, 8, ["placeholder", "value"])
                    ]))
                  : _createCommentVNode("", true),
                false
                  ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('employee.selectTasks')), 1),
                      _createVNode(_component_CheckboxMultipleSelect, {
                        options: _ctx.fetchedWorks,
                        value: _ctx.form.works,
                        onOnChange: _cache[13] || (_cache[13] = (selectedWorks)=> _ctx.form.works=selectedWorks)
                      }, null, 8, ["options", "value"])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["title"]),
            _createVNode(_component_Card, {
              title: _ctx.$t('label.allowLogin'),
              class: "transparent border flex"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_31, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.activateLogin')), 1),
                  _createVNode(_component_Checkbox, {
                    value: _ctx.allowLogin,
                    onOnChange: _ctx.handleAllowLoginChange
                  }, null, 8, ["value", "onOnChange"])
                ]),
                _createElementVNode("div", {
                  class: _normalizeClass(["timi-form-item col-6", { 'disabled': !_ctx.allowLogin }])
                }, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.loginName')), 1),
                  _withDirectives(_createElementVNode("input", {
                    type: "email",
                    disabled: !_ctx.allowLogin,
                    placeholder: _ctx.$t('placeholder.loginWithEmail'),
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.form.username) = $event))
                  }, null, 8, _hoisted_32), [
                    [_vModelText, _ctx.form.username]
                  ])
                ], 2)
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]),
        _createElementVNode("div", _hoisted_33, [
          _createElementVNode("div", _hoisted_34, [
            _createVNode(_component_Card, {
              title: _ctx.$t('employee.profile'),
              class: "transparent border flex"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("div", _hoisted_36, [
                    (_ctx.getProfileImageSrc)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          src: _ctx.getProfileImageSrc
                        }, null, 8, _hoisted_37))
                      : (_openBlock(), _createElementBlock("img", _hoisted_38))
                  ]),
                  _createVNode(_component_FileUpload, {
                    label: _ctx.$t('label.uploadProfileImage'),
                    onHandleSetImage: _ctx.handleSetProfileImage
                  }, null, 8, ["label", "onHandleSetImage"])
                ])
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createVNode(_component_Card, {
              title: _ctx.$t('employee.departments'),
              class: "transparent border flex"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.departmentOptions, (department) => {
                  return (_openBlock(), _createBlock(_component_Checkbox, {
                    value: department.assigned,
                    key: department.uid,
                    label: department.title,
                    onOnChange: (value) => _ctx.handleDepartmentChange(department, value)
                  }, null, 8, ["value", "label", "onOnChange"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["title"])
          ])
        ]),
        _createElementVNode("div", _hoisted_40, [
          (!_ctx.editMode)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                text: _ctx.$t('button.addEmployee'),
                primary: true,
                raised: true,
                onOnClick: _ctx.handleCreate
              }, null, 8, ["text", "onOnClick"]))
            : (_openBlock(), _createBlock(_component_Button, {
                key: 1,
                text: _ctx.$t('button.save'),
                primary: true,
                raised: true,
                onOnClick: _ctx.handleSave
              }, null, 8, ["text", "onOnClick"]))
        ])
      ], 32)
    ])
  ]))
}